import apiClient from 'services/axios'
import routeNames from 'routeNames/backendRouteNames'
import QueryString from 'qs'
import APIs from './apis'

export default class FaqCategoryService {
  getAllFaqCategories = async ({ query }) => {
    return apiClient.get(
      `${routeNames.GET_ALL_FAQ_CATEGORIES.path}?${QueryString.stringify(query)}`,
    )
  }

  createFaqCategory = async ({ body }) => {
    return apiClient.post(routeNames.CREATE_FAQ_CATEGORY.path, body)
  }

  updateFaqCategory = async ({ body, path }) => {
    return apiClient.patch(routeNames.UPDATE_FAQ_CATEGORY.path.replace(':id', path?.id), body)
  }

  deleteFaqCategory = async ({ params }) => {
    return apiClient.delete(APIs.DELETE_CATEGORY.replace(':id', params.id))
  }
}
