const actions = {
  SET_STATE: 'press/SET_STATE',
  CREATE_PRESS: 'press/CREATE_PRESS',
  GET_PRESS: 'press/GET_PRESS',
  UPDATE_PRESS: 'press/UPDATE_PRESS',
  GET_ALL_PRESSES: 'press/GET_ALL_PRESSES',
  DELETE_PRESS: 'press/DELETE_PRESS',
}

export default actions
