import { all, takeEvery, put, call } from 'redux-saga/effects'
import { doApiCall } from 'redux/helpers'
import { notification } from 'antd'
import FaqCategoryService from 'services/apis/faqCategory'
import actions from './actions'

const services = new FaqCategoryService()

export function* CREATE_FAQ_CATEGORY({ payload }) {
  const response = yield doApiCall(services.createFaqCategory, payload, actions.SET_STATE)
  if (response.success) {
    yield put({
      type: actions.GET_ALL_FAQ_CATEGORIES,
      payload: { query: { type: payload?.body?.type } },
    })
    notification.success({
      message: 'FAQ Category Posted Successfully',
      description: 'You have successfully posted the FAQ Category!',
    })
  }
}

export function* UPDATE_FAQ_CATEGORY({ payload }) {
  const response = yield doApiCall(services.updateFaqCategory, payload, actions.SET_STATE)
  if (response.success) {
    yield put({
      type: actions.GET_ALL_FAQ_CATEGORIES,
      payload: { query: { type: payload?.body?.type } },
    })
    notification.success({
      message: 'FAQ Category Updated Successfully',
      description: 'You have successfully updated the FAQ Category!',
    })
  }
}

export function* DELETE_FAQ_CATEGORY({ payload }) {
  yield put({
    type: 'faq_category/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(services.deleteFaqCategory, payload)
  if (success) {
    yield put({
      type: actions.GET_ALL_FAQ_CATEGORIES,
      payload: { query: { type: payload?.type } },
    })
    notification.success({
      message: success.data?.data?.message,
    })
  }

  yield put({
    type: 'faq_category/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_ALL_FAQ_CATEGORIES({ payload }) {
  const response = yield doApiCall(services.getAllFaqCategories, payload, actions.SET_STATE)
  if (response.success) {
    yield put({
      type: 'faq_category/SET_STATE',
      payload: {
        faqCategories: response.data,
        pagination: response.meta?.pagination,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ALL_FAQ_CATEGORIES, GET_ALL_FAQ_CATEGORIES),
    takeEvery(actions.CREATE_FAQ_CATEGORY, CREATE_FAQ_CATEGORY),
    takeEvery(actions.UPDATE_FAQ_CATEGORY, UPDATE_FAQ_CATEGORY),
    takeEvery(actions.DELETE_FAQ_CATEGORY, DELETE_FAQ_CATEGORY),
  ])
}
