/* eslint-disable */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import BookingService from 'services/apis/bookings'
import actions from './actions'
import { doApiCall } from 'redux/helpers'
import { notification } from 'antd'

const services = new BookingService()

export function* GET_ALL_BOOKINGS({ payload = { query } }) {
  const response = yield doApiCall(services.getAllBookings, payload, actions.SET_STATE)
  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        list: response.data,
        pagination: response.meta?.pagination,
      },
    })
  }
}

export function* UPDATE_BOOKING({ payload = { path, body } }) {
  const response = yield doApiCall(services.updateBooking, payload, actions.SET_STATE)
  if (response.success) {
    notification.success({
      message: response.data,
    })
  }
}

export function* GET_SINGLE_BOOKING({ payload = { path } }) {
  const response = yield doApiCall(services.getSingleBooking, payload, actions.SET_STATE)
  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        details: response.data,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_SINGLE_BOOKING, GET_SINGLE_BOOKING),
    takeEvery(actions.GET_ALL_BOOKINGS, GET_ALL_BOOKINGS),
    takeEvery(actions.UPDATE_BOOKING, UPDATE_BOOKING),
  ])
}
