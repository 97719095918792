import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import InvestorService from 'services/apis/investor'
import actions from './actions'

const services = new InvestorService()

export function* CREATE_INVESTOR({ payload }) {
  yield put({
    type: 'investor/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(services.createInvestor, payload)
  if (success) {
    yield put({
      type: 'investor/GET_ALL_INVESTORS',
    })
    yield history.push('/investors')
    notification.success({
      message: 'Investor Posted Successfully',
      description: 'You have successfully posted the Investor!',
    })
  }

  yield put({
    type: 'investor/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_INVESTOR({ payload }) {
  yield put({
    type: 'investor/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(services.deleteInvestor, payload)
  if (success) {
    yield put({
      type: 'investor/GET_ALL_INVESTORS',
    })
    yield history.push('/investors')
    notification.success({
      message: success?.data?.data?.message,
    })
  }

  yield put({
    type: 'investor/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_SIGNED_URL({ payload = { params: {} } }) {
  yield put({
    type: 'investor/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(services.getSignedUrl, payload)
  if (success && success.data && success.data.data) {
    const signedUrl = success.data.data
    yield put({
      type: 'investor/SET_STATE',
      payload: {
        signedUrl,
      },
    })
  }

  yield put({
    type: 'investor/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_ALL_INVESTORS({ payload = { params: {} } }) {
  yield put({
    type: 'investor/SET_STATE',
    payload: {
      loading: true,
    },
  })
  yield put({
    type: 'investor/SET_STATE',
    payload: {
      investor: {},
    },
  })
  const success = yield call(services.getAllInvestors, payload)

  if (success && success.data && success.data.data && success.data.meta) {
    yield put({
      type: 'investor/SET_STATE',
      payload: {
        investors: success.data.data,
        investorsMeta: success.data.meta,
      },
    })
  }

  yield put({
    type: 'investor/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ALL_INVESTORS, GET_ALL_INVESTORS),
    takeEvery(actions.GET_SIGNED_URL, GET_SIGNED_URL),
    takeEvery(actions.CREATE_INVESTOR, CREATE_INVESTOR),
    takeEvery(actions.DELETE_INVESTOR, DELETE_INVESTOR),
  ])
}
