import actions from './actions'

const initialState = {
  cities: [],
  tags: [],
  tagsMeta: {},
  types: [],
  properties: null,
  propertiesMeta: {},
  property: {},
  bookings: null,
  bookingsMeta: {},
  enquiries: null,
  enquiriesMeta: {},
  signedUrl: null,
  images: [],
  amenityLogos: {},
  formErrors: {},
  enquiryDetails: {},
}

export default function propertyReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
