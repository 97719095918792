import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import FaqService from 'services/apis/faq'
import { doApiCall } from 'redux/helpers'
import actions from './actions'

const services = new FaqService()

export function* CREATE_FAQ({ payload }) {
  yield put({
    type: 'faq/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(services.createFaq, payload)
  if (success) {
    yield put({
      type: actions.GET_ALL_FAQS,
      payload: { params: { categoryId: payload.body?.categoryId } },
    })
    yield history.push('/faqs')
    notification.success({
      message: 'FAQ Posted Successfully',
      description: 'You have successfully posted the FAQ!',
    })
  }

  yield put({
    type: 'faq/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_FAQ({ payload }) {
  const response = yield doApiCall(services.updateFaq, payload, actions.SET_STATE)
  if (response.success) {
    yield put({
      type: actions.GET_ALL_FAQS,
      payload: { params: { categoryId: payload.body?.category?.id } },
    })
    yield history.push('/faqs')
    notification.success({
      message: 'FAQ Updated Successfully',
      description: 'You have successfully updated the FAQ!',
    })
  }
}

export function* DELETE_FAQ({ payload }) {
  yield put({
    type: 'faq/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(services.deleteFaq, payload)
  if (success) {
    yield put({
      type: actions.GET_ALL_FAQS,
      payload: { params: { categoryId: payload.body?.categoryId } },
    })
    notification.success({
      message: success.data?.data?.message,
    })
  }

  yield put({
    type: 'faq/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_ALL_FAQS({ payload = { params: {} } }) {
  yield put({
    type: 'faq/SET_STATE',
    payload: {
      faq: {},
      loading: true,
    },
  })
  const success = yield call(services.getAllFaqs, payload)
  if (success && success.data && success.data.data && success.data.meta) {
    yield put({
      type: 'faq/SET_STATE',
      payload: {
        faqs: success.data.data,
        faqsMeta: success.data.meta,
      },
    })
  }

  yield put({
    type: 'faq/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ALL_FAQS, GET_ALL_FAQS),
    takeEvery(actions.CREATE_FAQ, CREATE_FAQ),
    takeEvery(actions.UPDATE_FAQ, UPDATE_FAQ),
    takeEvery(actions.DELETE_FAQ, DELETE_FAQ),
  ])
}
