import apiClient from 'services/axios'
import routeNames from 'routeNames/backendRouteNames'
import QueryString from 'qs'
import APIs from './api'

export default class PropertyService {
  getAllProperties = async ({ params }) => {
    return apiClient.get(`${APIs.GET_PROPERTIES}?${QueryString.stringify(params)}`)
  }

  getProperty = async ({ id, params }) => {
    let route = APIs.GET_PROPERTY
    route = route.replace(':id', id)
    return apiClient.get(route, { params })
  }

  getCities = async ({ params }) => {
    return apiClient.get(routeNames.GET_ALL_CITIES.path, { params })
  }

  getTypes = async ({ query }) => {
    return apiClient.get(`${routeNames.GET_ALL_TYPES.path}?${QueryString.stringify(query)}`)
  }

  getTags = async ({ query }) => {
    return apiClient.get(`${routeNames.GET_ALL_TAGS.path}?${QueryString.stringify(query)}`)
  }

  createProperty = async ({ body }) => {
    return apiClient.post(routeNames.CREATE_PROPERTY.path, body)
  }

  createCity = async ({ body }) => {
    return apiClient.post(routeNames.CREATE_CITY.path, body)
  }

  createType = async ({ body }) => {
    return apiClient.post(routeNames.CREATE_TYPE.path, body)
  }

  createTag = async ({ body }) => {
    return apiClient.post(routeNames.CREATE_TAG.path, body)
  }

  updateProperty = async ({ body, id }) => {
    let route = routeNames.UPDATE_PROPERTY.path
    route = route.replace(':id', id)
    return apiClient.patch(route, body)
  }

  getAllBookings = async ({ params }) => {
    return apiClient.get(routeNames.GET_ALL_BOOKINGS.path, { params })
  }

  getAllEnquiries = async ({ params }) => {
    return apiClient.get(routeNames.GET_ALL_ENQUIRIES.path, { params })
  }

  getEnquiriy = async ({ params, id }) => {
    return apiClient.get(routeNames.GET_ENQUIRY.path.replace(':id', id), { params })
  }

  updateEnquiriy = async ({ body, id }) => {
    return apiClient.patch(routeNames.UPDATE_ENQUIRY.path.replace(':id', id), body)
  }

  getPropertyCSV = async () => {
    return apiClient.get(routeNames.PROPERTY_CSV.path)
  }

  getBookingCSV = async () => {
    return apiClient.get(routeNames.BOOKING_CSV.path)
  }

  getEnquiryCSV = async () => {
    return apiClient.get(routeNames.ENQUIRY_CSV.path)
  }
}
