import QueryString from 'qs'
import apiClient from 'services/axios'
import APIs from './api'

export default class AmenityService {
  getAmenities = async ({ query }) => {
    return apiClient.get(`${APIs.GET_AMENITIES}?${QueryString.stringify(query)}`)
  }

  updateAmenity = async ({ path, body }) => {
    return apiClient.patch(APIs.UPDATE_AMENITY.replace(':id', path?.id), body)
  }

  createAmenity = async ({ body }) => {
    return apiClient.post(APIs.CREATE_AMENITY, body)
  }
}
