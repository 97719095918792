/* eslint-disable import/prefer-default-export */
import { call, put } from 'redux-saga/effects'
import { errors } from 'helper'

export function* doApiCall(service, payload, ACTION_SET_STATE) {
  yield showLoading(ACTION_SET_STATE, true)
  yield put({ type: ACTION_SET_STATE, payload: { formErrors: {} } })
  try {
    const response = yield call(service, payload)

    yield showLoading(ACTION_SET_STATE, false)

    if (response.status === 204) {
      return { success: true }
    }
    return response.data
  } catch (e) {
    yield showLoading(ACTION_SET_STATE, false)
    const { response } = e
    if (response) {
      const { status, data } = response
      if (status === 422) {
        yield put({
          type: ACTION_SET_STATE,
          payload: { formErrors: errors(data.errors) },
        })
      }
    }
  }
  return { success: false }
}

export function* showLoading(action, loading) {
  yield put({ type: action, payload: { loading } })
}
