/* eslint-disable */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import AmenityService from 'services/apis/amenities'
import actions from './actions'
import { doApiCall } from 'redux/helpers'

const services = new AmenityService()

export function* CREATE_AMENITY({ payload }) {
  const response = yield doApiCall(services.createAmenity, payload, actions.SET_STATE)
  if (response?.success) {
    yield put({
      type: actions.GET_AMENETIES,
      payload: {},
    })
    notification.success({
      message: 'Amenity Created Successfully',
      description: 'You have successfully created the amenity!',
    })
  }
}

export function* GET_AMENETIES({ payload = { params } }) {
  const response = yield doApiCall(services.getAmenities, payload, actions.SET_STATE)
  if (response?.success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        list: response.data,
        pagination: response.meta?.pagination,
      },
    })
  }
}

export function* UPDATE_AMENITY({ payload }) {
  const response = yield doApiCall(services.updateAmenity, payload, actions.SET_STATE)
  if (response?.success) {
    yield put({
      type: actions.GET_AMENETIES,
      payload: {
        query: {
          page: 1,
          perPage: 2000,
        },
      },
    })
    notification.success({
      message: 'Amenity successfully updated.',
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_AMENETIES, GET_AMENETIES),
    takeEvery(actions.CREATE_AMENITY, CREATE_AMENITY),
    takeEvery(actions.UPDATE_AMENITY, UPDATE_AMENITY),
  ])
}
