import moment from 'moment-timezone'

export function convertIntObj(obj) {
  const res = {}
  const keys = Object.keys(obj)
  const values = Object.values(obj)
  for (let i = 0; i < keys.length; i += 1) {
    res[keys[i]] = +values[i]
  }
  return res
}

export const FormatCurreny = (number) => {
  return new Intl.NumberFormat('en-IN', {}).format(number)
}

export function timestampForHuman(timestamp, format = 'DD/MM/YYYY hh:mm A') {
  if (!timestamp) return '--'
  timestamp = timestamp.split('T')
  const time = timestamp[1].split('.')
  const newDate = `${timestamp[0]} ${time[0]}`
  return moment.tz(newDate, 'America/Edmonton').format(format)
}

export const errors = (err) => {
  const messages = {}

  // eslint-disable-next-line consistent-return
  Object.keys(err).forEach((key) => {
    const errorMessage = err[key]
    if (!errorMessage) return ''
    messages[key] = typeof errorMessage === 'string' ? errorMessage : errorMessage.join(', ')
  })

  return messages
}

export const formErrorFeedback = (err, key) => {
  if (!err[key]) {
    return {}
  }
  return { hasFeedback: true, validateStatus: err[key] ? 'error' : '', help: err[key] }
}

export const formatCentsAsCurrency = (value) => {
  return value
    ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'CAD',
        maximumSignificantDigits: 20,
      }).format(value / 100)
    : '-'
}

export function formatDate(date, format, sign) {
  if (typeof date === 'string') date = new Date(date)
  function cb(key) {
    const f = new Intl.DateTimeFormat('en', { [key]: format[key] })
    return f.format(date)
  }

  return Object.keys(format).map(cb).join(sign)
}

export function dateViewFormat(date, format) {
  if (moment(date).isValid()) {
    return moment(date).format(format)
  }
  return '--'
}

export function getDate(date, delimitter = '-') {
  return (
    date.getFullYear().toString().padStart(2, '0') +
    delimitter +
    (date.getUTCMonth() + 1).toString().padStart(2, '0') +
    delimitter +
    date.getDate().toString().padStart(2, '0')
  )
}

export function getTime(date, delimitter = ':') {
  return (
    date.getHours().toString().padStart(2, '0') +
    delimitter +
    date.getMinutes().toString().padStart(2, '0') +
    delimitter +
    date.getSeconds().toString().padStart(2, '0')
  )
}

export const checkDate = (dateTime, formate) => {
  const date = moment(dateTime, formate, true)

  if (date.isValid()) {
    return true
  }
  return false
}

export const getDateDiff = (firstDate, secondDate, unit) => {
  return firstDate.diff(secondDate, unit)
}

export const addingInDate = (currentDate, amount, unit) => {
  return currentDate.add(amount, unit)
}

const getParseDate = (list) => {
  const parsedDate = {}
  if (list && Array.isArray(list) && list.length) {
    for (let i = 0; i < list.length; i += 1) {
      const ds = list[i]
      if (parsedDate[`${ds.index}`]) {
        parsedDate[`${ds.index}`] = ds.value
      } else {
        parsedDate[`${ds.index}`] = ds.value
      }
    }
  }
  return parsedDate
}

const DATE_FORMATE = 'YYYY-MM-DD'

export const getChartData = (list, date, isPayment = false) => {
  const firstDate = moment([date[0]], DATE_FORMATE)
  const secondDate = moment(date[1], DATE_FORMATE)
  const dateDiff = getDateDiff(secondDate, firstDate, 'day')

  const parsedDates = getParseDate(list)

  const chartData = { labels: [], data: [] }

  let total = 0

  let rollingDate = firstDate
  for (let i = 0; i < dateDiff; i += 1) {
    if (parsedDates[`${rollingDate.format(DATE_FORMATE)}`]) {
      const data = parsedDates[`${rollingDate.format(DATE_FORMATE)}`]
      total += +data
      chartData.labels.push(`${rollingDate.format('DD MMM')}`)
      chartData.data.push(isPayment ? +data / 100 : data)
    }
    rollingDate = addingInDate(rollingDate, 1, 'day')
  }

  return { chartData, total }
}
