export default async function getMenuData() {
  return [
    // VB:REPLACE-START:MENU-CONFIG
    {
      category: true,
      title: 'Properties ',
      key: 'q7r3h3',
    },
    {
      title: 'Properties',
      key: '__properties',
      url: '/properties',
      icon: 'fe fe-home',
    },
    {
      title: 'Amenities',
      key: '__amenities',
      url: '/amenities',
      icon: 'icmn-clipboard',
    },
    {
      title: 'Cities',
      key: '__cities',
      url: '/cities',
      icon: 'fe fe-map-pin',
    },
    {
      category: true,
      title: 'Booking Requests',
      key: 'q7r3h3',
    },
    {
      title: 'Booking Requests',
      key: '__bookings',
      url: '/bookings',
      icon: 'fe fe-users',
    },
    {
      category: true,
      title: 'Landlord Enquiries',
      key: 'q7r3h3',
    },
    {
      title: 'Landlord Enquiries',
      key: '__enquiries',
      url: '/enquiries',
      icon: 'fe fe-clipboard',
    },
    {
      category: true,
      title: 'Website Settings',
      key: 'q7r3h3',
    },
    {
      title: 'Investors',
      key: '__investors',
      url: '/investors',
      icon: 'fe fe-users',
    },
    {
      title: 'Press',
      key: '__presses',
      url: '/press',
      icon: 'fe fe-video',
    },
    {
      title: 'FAQ',
      key: '__faqs',
      url: '/faqs',
      icon: 'fe fe-help-circle',
    },

    // VB:REPLACE-END:MENU-CONFIG
  ]
}
