/* eslint-disable */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import PropertyService from 'services/apis/property'
import actions from './actions'
import { doApiCall } from 'redux/helpers'

const services = new PropertyService()

export function* CREATE_PROPERTY({ payload }) {
  const response = yield doApiCall(services.createProperty, payload, actions.SET_STATE)
  if (response?.success) {
    yield put({
      type: 'property/GET_ALL_PROPERTIES',
    })
    yield history.push('/')
    notification.success({
      message: 'Property created successfully',
      description: 'You have successfully posted the property!',
    })
  }
}

export function* UPDATE_PROPERTY({ payload }) {
  const response = yield doApiCall(services.updateProperty, payload, actions.SET_STATE)
  if (response?.success) {
    yield history.push('/')
    notification.success({
      message: 'Property Updated Successfully',
      description: 'You have successfully updated the property!',
    })
  }
}

export function* CREATE_CITY({ payload }) {
  yield put({
    type: 'city/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(services.createCity, payload)
  if (success) {
    yield put({
      type: 'property/GET_CITIES',
    })
    notification.success({
      message: 'City Created Successfully',
      description: 'You have successfully created the city!',
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_TYPE({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(services.createType, payload)
  if (success) {
    yield put({
      type: 'property/GET_TYPES',
    })
    notification.success({
      message: 'Type Created Successfully',
      description: 'You have successfully created the type!',
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_TAG({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(services.createTag, payload)
  if (success) {
    yield put({
      type: 'property/GET_TAGS',
    })
    notification.success({
      message: 'Tag Created Successfully',
      description: 'You have successfully created the tag!',
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_ALL_PROPERTIES({ payload = { params: {} } }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  yield put({
    type: 'property/SET_STATE',
    payload: {
      property: {},
    },
  })
  const success = yield call(services.getAllProperties, payload)
  if (success && success.data && success.data.data && success.data.meta) {
    yield put({
      type: 'property/SET_STATE',
      payload: {
        properties: success.data.data,
        propertiesMeta: success.data.meta,
      },
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_PROPERTY({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  yield put({
    type: 'property/SET_STATE',
    payload: {
      property: {},
    },
  })
  const success = yield call(services.getProperty, payload)
  if (success && success.data && success.data.data) {
    const data = success.data.data

    yield put({
      type: 'property/SET_STATE',
      payload: {
        property: data,
      },
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_CITIES({ payload = { params: {} } }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(services.getCities, payload)
  if (success && success.data && success.data.data) {
    const cities = success.data.data
    yield put({
      type: 'property/SET_STATE',
      payload: {
        cities,
      },
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_TYPES({ payload = { params: {} } }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(services.getTypes, payload)
  if (success && success.data && success.data.data) {
    const types = success.data.data
    yield put({
      type: 'property/SET_STATE',
      payload: {
        types,
      },
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_TAGS({ payload = { params: {} } }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(services.getTags, payload)
  if (success && success.data && success.data.data && success.data.meta) {
    const tags = success.data.data
    const tagsMeta = success.data.meta.pagination
    yield put({
      type: 'property/SET_STATE',
      payload: {
        tagsMeta,
        tags,
      },
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_ALL_BOOKINGS({ payload = { params: {} } }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(services.getAllBookings, payload)

  if (success && success.data && success.data.data && success.data.meta) {
    yield put({
      type: 'property/SET_STATE',
      payload: {
        bookings: success.data.data,
        bookingsMeta: success.data.meta,
      },
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_ALL_ENQUIRIES({ payload = { params: {} } }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  yield put({
    type: 'property/SET_STATE',
    payload: {
      property: {},
    },
  })
  const success = yield call(services.getAllEnquiries, payload)

  if (success && success.data && success.data.data && success.data.meta) {
    yield put({
      type: 'property/SET_STATE',
      payload: {
        enquiries: success.data.data,
        enquiriesMeta: success.data.meta,
      },
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_ENQUIRY({ payload = { params: {}, id } }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  yield put({
    type: 'property/SET_STATE',
    payload: {
      enquiryDetails: {},
    },
  })
  const success = yield call(services.getEnquiriy, payload)
  if (success && success.data && success.data.data) {
    yield put({
      type: 'property/SET_STATE',
      payload: {
        enquiryDetails: success.data.data,
      },
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_ENQUIRY({ payload = { body: {}, id } }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(services.updateEnquiriy, payload)

  if (success && success.data && success.data.data) {
    notification.success({
      message: success.data.data,
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_SIGNED_URL({ payload = { params: {} } }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(services.getSignedUrl, payload)
  if (success && success.data && success.data.data) {
    const signedUrl = success.data.data
    yield put({
      type: 'property/SET_STATE',
      payload: {
        signedUrl,
      },
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_PROPERTY_CSV() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(services.getPropertyCSV)
  if (success && success.data && success.data.data) {
    const data = success.data.data

    notification.success({
      message: data,
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_ENQUIRY_CSV() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(services.getEnquiryCSV)

  if (success && success.data && success.data.data) {
    const data = success.data.data
    notification.success({
      message: data,
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_BOOKING_CSV() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(services.getBookingCSV)
  if (success && success.data && success.data.data) {
    const data = success.data.data
    notification.success({
      message: data,
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ALL_PROPERTIES, GET_ALL_PROPERTIES),
    takeEvery(actions.GET_CITIES, GET_CITIES),
    takeEvery(actions.GET_TAGS, GET_TAGS),
    takeEvery(actions.GET_TYPES, GET_TYPES),
    takeEvery(actions.GET_PROPERTY, GET_PROPERTY),
    takeEvery(actions.CREATE_TYPE, CREATE_TYPE),
    takeEvery(actions.CREATE_TAG, CREATE_TAG),
    takeEvery(actions.CREATE_PROPERTY, CREATE_PROPERTY),
    takeEvery(actions.CREATE_CITY, CREATE_CITY),
    takeEvery(actions.UPDATE_PROPERTY, UPDATE_PROPERTY),
    takeEvery(actions.GET_ALL_BOOKINGS, GET_ALL_BOOKINGS),
    takeEvery(actions.GET_ALL_ENQUIRIES, GET_ALL_ENQUIRIES),
    takeEvery(actions.GET_SIGNED_URL, GET_SIGNED_URL),
    takeEvery(actions.GET_PROPERTY_CSV, GET_PROPERTY_CSV),
    takeEvery(actions.GET_BOOKING_CSV, GET_BOOKING_CSV),
    takeEvery(actions.GET_ENQUIRY_CSV, GET_ENQUIRY_CSV),
    takeEvery(actions.GET_ENQUIRY, GET_ENQUIRY),
    takeEvery(actions.UPDATE_ENQUIRY, UPDATE_ENQUIRY),
  ])
}
