import apiClient from 'services/axios'
import routeNames from 'routeNames/backendRouteNames'

export default class UserService {
  login = async (payload) => {
    return apiClient.post(routeNames.LOGIN.path, payload)
  }

  getProfile = async () => {
    return apiClient.get(routeNames.GET_PROFILE.path)
  }
}
