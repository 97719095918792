import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import property from './property/sagas'
import investor from './investor/sagas'
import faq from './faq/sagas'
import faqCategory from './faqCategory/sagas'
import press from './press/sagas'
import amenities from './amenities/sagas'
import cities from './cities/sagas'
import bookings from './bookings/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    property(),
    investor(),
    faq(),
    faqCategory(),
    press(),
    amenities(),
    cities(),
    bookings(),
  ])
}
