const backendRoutesName = {
  LOGIN: { path: '/auth/login' },
  GET_PROFILE: { path: '/admin/profile' },
  GET_ALL_PROPERTIES: { path: '/properties' },
  GET_PROPERTY: { path: '/properties/:id' },
  CREATE_PROPERTY: { path: '/admin/properties' },
  UPDATE_PROPERTY: { path: '/admin/properties/:id' },
  GET_ALL_CITIES: { path: '/properties/cities' },
  CREATE_CITY: { path: '/admin/properties/cities' },
  GET_ALL_TAGS: { path: '/properties/tags' },
  CREATE_TAG: { path: '/admin/properties/tags' },
  GET_ALL_AMENITIES: { path: '/properties/amenities' },
  CREATE_AMENITY: { path: '/admin/properties/amenities' },
  GET_ALL_TYPES: { path: '/properties/types' },
  CREATE_TYPE: { path: '/admin/properties/types' },
  GET_SIMILAR_PROPERTIES: { path: '/properties/similar' },
  GET_ALL_AREA: { path: '/properties/area' },
  GET_ALL_BOOKINGS: { path: '/admin/bookings' },
  GET_ALL_ENQUIRIES: { path: '/admin/enquiries' },
  GET_ENQUIRY: { path: '/admin/enquiries/:id' },
  UPDATE_ENQUIRY: { path: '/admin/enquiries/:id' },
  GET_ALL_INVESTORS: { path: '/settings/investor' },
  CREATE_INVESTOR: { path: '/admin/settings/investor' },
  GET_ALL_FAQS: { path: '/settings/faqs' },
  CREATE_FAQ: { path: '/admin/faqs' },
  GET_ALL_FAQ_CATEGORIES: { path: '/settings/faqs/category' },
  CREATE_FAQ_CATEGORY: { path: '/admin/faqs/category' },
  UPDATE_FAQ_CATEGORY: { path: '/admin/faqs/category/:id' },
  UPDATE_FAQ: { path: 'admin/faqs/:id' },
  GET_ALL_PRESSES: { path: '/settings/in-the-press' },
  CREATE_PRESS: { path: '/admin/settings/in-the-press' },
  CREATE_FAQ_ANSWER: { path: '/admin/settings/faqs/answer' },
  PROPERTY_CSV: { path: '/admin/properties/getCSV' },
  BOOKING_CSV: { path: '/admin/bookings/getCSV' },
  ENQUIRY_CSV: { path: '/admin/enquiries/getCSV' },
}

export default backendRoutesName
