import { all, takeEvery, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import PressService from 'services/apis/press'
import { doApiCall } from 'redux/helpers'
import actions from './actions'

const services = new PressService()

export function* CREATE_PRESS({ payload }) {
  const response = yield doApiCall(services.createPress, payload, actions.SET_STATE)
  if (response.success) {
    yield put({
      type: 'press/GET_ALL_PRESSES',
      payload: {},
    })
    yield history.push('/press')
    notification.success({
      message: 'Press Posted Successfully',
      description: 'You have successfully posted the Press!',
    })
  }
}

export function* UPDATE_PRESS({ payload }) {
  const response = yield doApiCall(services.updatePress, payload, actions.SET_STATE)
  if (response.success) {
    yield put({
      type: 'press/GET_ALL_PRESSES',
      payload: {},
    })
    yield history.push('/press')
    notification.success({
      message: 'Press update successfully',
      description: 'You have successfully updated the press!',
    })
  }
}

export function* GET_PRESS({ payload }) {
  const response = yield doApiCall(services.getPress, payload, actions.SET_STATE)
  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        details: response.data,
      },
    })
  }
}

export function* DELETE_PRESS({ payload }) {
  const response = yield doApiCall(services.deletePress, payload, actions.SET_STATE)
  if (response) {
    yield put({
      type: 'press/GET_ALL_PRESSES',
      payload: {},
    })
    yield history.push('/press')
    notification.success({
      message: response.data?.message,
    })
  }
}

export function* GET_ALL_PRESSES({ payload = { params: {} } }) {
  const response = yield doApiCall(services.getAllPresses, payload, actions.SET_STATE)
  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        presses: response.data,
        pagination: response.meta?.pagination,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ALL_PRESSES, GET_ALL_PRESSES),
    takeEvery(actions.CREATE_PRESS, CREATE_PRESS),
    takeEvery(actions.DELETE_PRESS, DELETE_PRESS),
    takeEvery(actions.UPDATE_PRESS, UPDATE_PRESS),
    takeEvery(actions.GET_PRESS, GET_PRESS),
  ])
}
