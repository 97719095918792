const actions = {
  SET_STATE: 'property/SET_STATE',
  CREATE_PROPERTY: 'property/CREATE_PROPERTY',
  CREATE_TYPE: 'property/CREATE_TYPE',
  CREATE_CITY: 'property/CREATE_CITY',
  CREATE_TAG: 'property/CREATE_TAG',
  GET_ALL_PROPERTIES: 'property/GET_ALL_PROPERTIES',
  GET_PROPERTY: 'property/GET_PROPERTY',
  GET_CITIES: 'property/GET_CITIES',
  GET_TYPES: 'property/GET_TYPES',
  GET_TAGS: 'property/GET_TAGS',
  UPDATE_PROPERTY: 'property/UPDATE_PROPERTY',
  GET_ALL_BOOKINGS: 'property/GET_BOOKINGS',
  GET_ALL_ENQUIRIES: 'property/GET_ENQUIRIES',
  GET_SIGNED_URL: 'property/SIGNED_URL',
  GET_PROPERTY_CSV: 'property/GET_PROPERTY_CSV',
  GET_BOOKING_CSV: 'property/GET_BOOKING_CSV',
  GET_ENQUIRY_CSV: 'property/GET_ENQUIRY_CSV',
  GET_ENQUIRY: 'property/GET_ENQUIRY',
  UPDATE_ENQUIRY: 'property/UPDATE_ENQUIRY',
}

export default actions
