import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import AuthService from 'services/apis/user'
import { doApiCall } from 'redux/helpers'
import actions from './actions'

const services = new AuthService()

export function* LOGIN({ payload }) {
  const response = yield doApiCall(services.login, payload, actions.SET_STATE)
  if (response.success) {
    const accessToken = response.data.access_token
    localStorage.setItem('accessToken', accessToken)

    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    yield put({
      type: actions.SET_STATE,
      payload: { authorized: true },
    })
    yield history.push('/')
    notification.success({
      message: 'Logged In',
      description: 'You have successfully logged in!',
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  if (localStorage.getItem('accessToken')) {
    const response = yield call(services.getProfile)
    if (response && response.data && response.data.data) {
      const { id, email, name, role } = response.data.data
      yield put({
        type: 'user/SET_STATE',
        payload: {
          id,
          name,
          email,
          role,
          authorized: true,
        },
      })
    }
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  localStorage.clear('accessToken')

  yield put({
    type: 'property/SET_STATE',
    payload: {
      cities: [],
      tags: [],
      tagsMeta: {},
      types: [],
      amenities: [],
      amenitiesMeta: {},
      properties: null,
      propertiesMeta: {},
      property: {},
      bookings: null,
      bookingsMeta: {},
      enquiries: null,
      enquiriesMeta: {},
      signedUrl: null,
    },
  })

  yield history.push('/')
  notification.success({
    message: 'Logged Out',
    description: 'You have successfully logged out!',
  })

  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      authorized: false,
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
