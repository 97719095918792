import QueryString from 'qs'
import apiClient from 'services/axios'
import APIs from './apis'

export default class FaqService {
  getAllFaqs = async ({ params }) => {
    return apiClient.get(`${APIs.GET_FAQS}?${QueryString.stringify(params)}`)
  }

  createFaq = async ({ body }) => {
    return apiClient.post(APIs.CREATE_FAQ, body)
  }

  updateFaq = async ({ body, path }) => {
    let route = APIs.UPDATE_FAQ
    route = route.replace(':id', path.id)
    return apiClient.patch(route, body)
  }

  deleteFaq = async ({ params }) => {
    return apiClient.delete(APIs.DELETE_FAQ.replace(':id', params.id))
  }
}
