import apiClient from 'services/axios'
import routeNames from 'routeNames/backendRouteNames'
import APIs from './apis'

export default class InvestorService {
  getAllInvestors = async ({ params }) => {
    return apiClient.get(routeNames.GET_ALL_INVESTORS.path, { params })
  }

  createInvestor = async ({ body }) => {
    return apiClient.post(routeNames.CREATE_INVESTOR.path, body)
  }

  deleteInvestor = async ({ params }) => {
    return apiClient.delete(APIs.DELETE_INVESTOR.replace(':id', params.id))
  }
}
