/* eslint-disable */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import CityService from 'services/apis/cities'
import actions from './actions'
import { doApiCall } from 'redux/helpers'

const services = new CityService()

export function* CREATE_CITY({ payload }) {
  const response = yield doApiCall(services.createCity, payload, actions.SET_STATE)
  if (response?.success) {
    yield put({
      type: actions.GET_CITIES,
      payload: {},
    })
    notification.success({
      message: 'City Created Successfully',
      description: 'You have successfully created the city!',
    })
  }
}

export function* GET_CITIES({ payload = { query } }) {
  const response = yield doApiCall(services.getCities, payload, actions.SET_STATE)
  if (response?.success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        list: response.data,
        pagination: response.meta?.pagination,
      },
    })
  }
}

export function* UPDATE_CITY({ payload }) {
  const response = yield doApiCall(services.updateCity, payload, actions.SET_STATE)
  if (response?.success) {
    yield put({
      type: actions.GET_CITIES,
      payload: {},
    })
    notification.success({
      message: response.data?.message,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_CITIES, GET_CITIES),
    takeEvery(actions.CREATE_CITY, CREATE_CITY),
    takeEvery(actions.UPDATE_CITY, UPDATE_CITY),
  ])
}
