import QueryString from 'qs'
import apiClient from 'services/axios'
import APIs from './api'

export default class BookingService {
  getAllBookings = async ({ query }) => {
    return apiClient.get(`${APIs.GET_BOOKINGS}?${QueryString.stringify(query)}`)
  }

  getSingleBooking = async ({ path, query }) => {
    return apiClient.get(
      `${APIs.GET_BOOKING.replace(':id', path?.id)}?${QueryString.stringify(query)}`,
    )
  }

  updateBooking = async ({ path, body }) => {
    return apiClient.patch(`${APIs.UPDATE_BOOKING.replace(':id', path?.id)}`, body)
  }
}
