import QueryString from 'qs'
import apiClient from 'services/axios'
import APIs from './api'

export default class PressService {
  getAllPresses = async ({ params }) => {
    return apiClient.get(`${APIs.GET_PRESS}?${QueryString.stringify(params)}`)
  }

  getPress = async ({ path }) => {
    return apiClient.get(APIs.GET_SINGLE_PRESS.replace(':id', path?.id))
  }

  createPress = async ({ body }) => {
    return apiClient.post(APIs.CREATE_PRESS, body)
  }

  updatePress = async ({ body, path }) => {
    return apiClient.patch(APIs.UPDATE_PRESS.replace(':id', path?.id), body)
  }

  deletePress = async ({ params }) => {
    return apiClient.delete(APIs.DELETE_PRESS.replace(':id', params.id))
  }
}
