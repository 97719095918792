/* eslint-disable */
import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from './layouts'

const routes = [
  // VB:REPLACE-START:ROUTER-CONFIG
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/dashboard')),
    exact: true,
  },

  {
    path: '/properties',
    Component: lazy(() => import('pages/properties/getAllProperties')),
    exact: true,
  },

  {
    path: '/properties/:id',
    Component: lazy(() => import('pages/properties/postProperty')),
    exact: true,
  },
  {
    path: '/amenities',
    Component: lazy(() => import('pages/amenities')),
    exact: true,
  },

  {
    path: '/cities',
    Component: lazy(() => import('pages/cities')),
    exact: true,
  },

  {
    path: '/bookings',
    Component: lazy(() => import('pages/bookings/getAllBookings')),
    exact: true,
  },
  {
    path: '/bookings/:id',
    Component: lazy(() => import('pages/bookings/details')),
    exact: true,
  },
  {
    path: '/enquiries',
    Component: lazy(() => import('pages/enquiries/getAllEnquires')),
    exact: true,
  },
  {
    path: '/enquiries/:id',
    Component: lazy(() => import('pages/enquiries/details')),
    exact: true,
  },

  {
    path: '/investors',
    Component: lazy(() => import('pages/investors/getInvestors')),
    exact: true,
  },

  {
    path: '/investors/post',
    Component: lazy(() => import('pages/investors/createInvestors')),
    exact: true,
  },

  {
    path: '/press',
    Component: lazy(() => import('pages/press/getAllPress')),
    exact: true,
  },

  {
    path: '/press/:id',
    Component: lazy(() => import('pages/press/createPress')),
    exact: true,
  },

  {
    path: '/faqs',
    Component: lazy(() => import('pages/faqs/getAllFaq')),
    exact: true,
  },

  // VB:REPLACE-END:ROUTER-CONFIG
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={(state) => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    {/* VB:REPLACE-NEXT-LINE:ROUTER-REDIRECT */}
                    <Route exact path="/" render={() => <Redirect to="/properties" />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
