import QueryString from 'qs'
import apiClient from 'services/axios'
import APIs from './api'

export default class CityService {
  getCities = async ({ query }) => {
    return apiClient.get(`${APIs.GET_CITIES}?${QueryString.stringify(query)}`)
  }

  updateCity = async ({ path, body }) => {
    return apiClient.patch(APIs.UPDATE_CITY.replace(':id', path?.id), body)
  }

  createCity = async ({ body }) => {
    return apiClient.post(APIs.CREATE_CITY, body)
  }
}
