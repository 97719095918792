import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import property from './property/reducers'
import investor from './investor/reducers'
import faq from './faq/reducers'
import faqCategory from './faqCategory/reducers'
import press from './press/reducers'
import amenities from './amenities/reducers'
import cities from './cities/reducers'
import bookings from './bookings/reducers'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    property,
    amenities,
    investor,
    faq,
    faqCategory,
    press,
    cities,
    bookings,
  })
