import React from 'react'
import { Space, Button } from 'antd'
import { EyeFilled } from '@ant-design/icons'
import UserMenu from './UserMenu'
import style from './style.module.scss'

const TopBar = () => {
  return (
    <div className={style.topbar}>
      <div style={{ position: 'absolute', right: '2rem' }}>
        <UserMenu />
      </div>
      <Space size="middle" style={{ position: 'absolute', right: '8rem' }}>
        <a href={process.env.REACT_APP_FRONTEND_URL} rel="noreferrer" target="_blank">
          {' '}
          <Button shape="round" icon={<EyeFilled />}>
            View Website
          </Button>
        </a>
      </Space>
    </div>
  )
}

export default TopBar
